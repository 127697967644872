import * as Sentry from '@sentry/react';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    integrations: [
        Sentry.replayIntegration({
            networkDetailAllowUrls: [window.location.origin],
        }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^www\.butchercrowd\.com\.au/],
    ignoreUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    denyUrls: [
        'https://app.gleen.ai/sdk/gleenWidget.js',
        'https://app.gleen.ai/js/chat-widget/main.js',
    ],
});

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

window.cdn = 'https://cdn.butchercrowd.com.au/static/';
