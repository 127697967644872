import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { useState } from 'react';
import { CheckIcon } from '@/components/common/Icons';
import Modal from '@/components/modals/Modal';
import formatDollarAmount from '@/utils/formatDollarAmount';

type Props = {
    heading: string;
    title?: string;
    description?: string;
    value: string;
    className?: string;
    image: string;
    imageAlt?: string;
    after?: React.ReactNode;
    modal?: boolean;
    children?: React.ReactNode;
    smaller?: boolean;
    price?: number;
};

export default function BoxRadio({
    heading,
    image,
    value,
    className,
    imageAlt,
    title,
    description,
    after,
    modal,
    children,
    smaller = false,
    price,
}: Props) {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <RadioGroup.Item
                id={value}
                value={value}
                className={clsx(
                    'group relative m-2.5 flex w-full max-w-xs cursor-pointer flex-col border border-black focus:outline-none focus-visible:border-primary focus-visible:outline focus-visible:outline-4 focus-visible:outline-primary checked:shadow-block sm:m-0',
                    className
                )}
            >
                <div className="flex w-full items-center justify-between bg-black transition-colors group-hover:bg-darkenPrimary group-checked:bg-primary xl:whitespace-nowrap">
                    <h3
                        className={`my-3 px-4 font-bold uppercase text-white
                        ${
                            smaller
                                ? 'md:text-md text-sm'
                                : 'text-lg md:text-xl'
                        }`}
                    >
                        {heading}
                    </h3>
                    {price && (
                        <span className="mb-auto bg-primary px-[14px] py-[6px] text-white transition-colors group-hover:bg-secondary group-checked:bg-secondary">
                            {formatDollarAmount(price)}
                        </span>
                    )}
                </div>
                <img
                    className="max-h-[190px] min-h-[190px] w-full xs:object-cover"
                    src={image}
                    alt={imageAlt}
                />
                <div className="relative flex w-full flex-col bg-white">
                    <div className="flex flex-row justify-between px-4 py-6">
                        <div className="flex-1 text-left">
                            {title && (
                                <h4
                                    className={`font-neue font-medium
                                    ${smaller ? 'text-xl' : 'text-2xl'}`}
                                >
                                    {title}
                                </h4>
                            )}
                            {description && (
                                <div
                                    className={`prose text-black
                                        ${smaller ? 'text-xs' : 'text-base'}`}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            )}
                            {modal && (
                                <div
                                    id={value}
                                    tabIndex={0}
                                    role="button"
                                    className="mt-2 inline-block font-neue text-xs font-normal uppercase tracking-widest underline transition-colors hover:text-primary"
                                    onClick={() => setShowModal(true)}
                                    onKeyDown={(event) => {
                                        if (
                                            event.key === 'enter' ||
                                            event.key === 'space'
                                        )
                                            setShowModal(true);
                                    }}
                                >
                                    Learn more
                                </div>
                            )}
                        </div>
                        <div className="relative m-2 h-10 w-10 border border-black">
                            <RadioGroup.Indicator>
                                <CheckIcon className="absolute -top-4 -right-5 h-14 w-14" />
                            </RadioGroup.Indicator>
                        </div>
                    </div>
                </div>
                {after}
            </RadioGroup.Item>
            <Modal
                title={heading}
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                {children}
            </Modal>
        </>
    );
}
