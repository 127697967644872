import clsx from 'clsx';
import { FieldValidator, useField } from 'formik';
import {
    HTMLInputTypeAttribute,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { disableAutoCompleteProps } from '@/utils/inputs';
import { EyeIcon } from '../common/Icons';
import { InputGroupContext } from './InputGroup';

export type TextInputProps = {
    id?: string;
    name?: string;
    type?: HTMLInputTypeAttribute;
    placeholder?: string;
    autoComplete?: string;
    validate?: FieldValidator;
    rounded?: boolean;
    disabled?: boolean;
    maxLength?: number;
    min?: string;
    placeAutoCompleteRef?: any;
    max?: string;
    onFocus?(e: any): void;
    onKeyUp?(e: any): void;
    onKeyDown?(e: any): void;
    onBlur?(e: any): void;
    height?: number;
    dollarInput?: boolean;
    center?: boolean;
    inputMode?:
        | 'none'
        | 'text'
        | 'tel'
        | 'url'
        | 'email'
        | 'numeric'
        | 'decimal'
        | 'search';
};

export default function TextInput(props: TextInputProps) {
    const {
        inputId: ctxId,
        name: ctxName,
        errorId,
    } = useContext(InputGroupContext);

    const {
        id = ctxId,
        name = ctxName,
        type,
        autoComplete,
        validate,
        placeholder,
        rounded,
        disabled,
        placeAutoCompleteRef,
        maxLength,
        min,
        max,
        onFocus,
        onKeyUp,
        onKeyDown,
        onBlur,
        height,
        dollarInput,
        center,
        inputMode,
    } = props;

    const [field, meta] = useField({
        name,
        validate,
        type,
    });
    const [visible, setVisible] = useState(false);

    field.value = field.value ?? '';

    const { error, touched } = meta;
    const inputRef = useRef(null);

    const handleWheel = (e: any) => {
        e.preventDefault();
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const inputElement = inputRef.current;

        if (inputElement) {
            (inputElement as any).addEventListener('wheel', handleWheel, {
                passive: false,
            });

            return () => {
                (inputElement as any).removeEventListener(
                    'wheel',
                    handleWheel,
                    {
                        passive: false,
                    }
                );
            };
        }
    }, []);

    return (
        <div className="relative mt-1">
            {onBlur ? (
                <input
                    id={id}
                    type={
                        // eslint-disable-next-line no-nested-ternary
                        type === 'password'
                            ? visible
                                ? 'text'
                                : 'password'
                            : type
                    }
                    {...(autoComplete === 'off'
                        ? disableAutoCompleteProps
                        : {})}
                    {...field}
                    placeholder={placeholder}
                    className={clsx(
                        'form-input',
                        error && touched && 'error',
                        rounded && 'rounded',
                        height && `h-${height}`,
                        center && '!text-center',
                        type === 'number' &&
                            dollarInput &&
                            '!text-right !text-xl'
                    )}
                    aria-invalid={Boolean(error && touched)}
                    aria-describedby={error && touched ? errorId : undefined}
                    disabled={disabled}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    onFocus={onFocus}
                    ref={inputRef}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    step="0.01"
                    inputMode={inputMode || 'text'}
                />
            ) : (
                <input
                    id={id}
                    type={
                        // eslint-disable-next-line no-nested-ternary
                        type === 'password'
                            ? visible
                                ? 'text'
                                : 'password'
                            : type
                    }
                    {...(autoComplete === 'off'
                        ? disableAutoCompleteProps
                        : {})}
                    {...field}
                    placeholder={placeholder}
                    className={clsx(
                        'form-input',
                        error && touched && 'error',
                        rounded && 'rounded',
                        height && `h-${height}`,
                        center && '!text-center',
                        type === 'number' &&
                            dollarInput &&
                            '!text-right !text-xl'
                    )}
                    aria-invalid={Boolean(error && touched)}
                    aria-describedby={error && touched ? errorId : undefined}
                    disabled={disabled}
                    maxLength={maxLength}
                    min={min}
                    max={max}
                    onFocus={onFocus}
                    ref={placeAutoCompleteRef}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    inputMode={inputMode || 'text'}
                />
            )}
            {type === 'number' && dollarInput && (
                <div
                    className={clsx(
                        'absolute inset-y-0 left-6 flex items-center pr-3 text-lg'
                    )}
                >
                    $
                </div>
            )}
            {type === 'password' && (
                <div
                    className={clsx(
                        'absolute inset-y-0 right-0 flex items-center pr-3'
                    )}
                >
                    <button
                        type="button"
                        aria-hidden
                        onClick={() => setVisible((v) => !v)}
                    >
                        {visible ? <EyeIcon /> : <EyeIcon />}
                    </button>
                </div>
            )}
        </div>
    );
}
